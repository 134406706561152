import React from "react";
import { Link } from 'react-router-dom';
export default function IndexPage() {
    return (
        <>
            <div className="mx-auto container">
            <div class="text-center flex flex-col justify-center items-center space-y-4 mt-32">
                        <h1 class="text-3xl lg:text-6xl font-semibold leading-9 text-center text-gray-800">NTMGS Team</h1>
                        {/* <p class="text-base leading-6 md:leading-4 text-center text-gray-600">Dive deep into the world of fashion with our lastest blogs updates.</p> */}
        </div>
                <div className="flex flex-wrap items-center justify-center relative py-12">
                    <div className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/teamtarunpng.png" alt="oliver" className="w-full" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                            <Link to="https://www.instagram.com/tarunrajputmodelmentor/"> 
                                          
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Tarun Rajput</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Founder, NTMGS</h5>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/dimplekapoor.jpg" className="w-full" alt="Thea" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                        <Link to="https://www.instagram.com/dimpplekpoor/"> 
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Dimple Kapoor</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={42} height={4} viewBox="0 0 42 4" fill="none">
                                        <line y1={2} x2="41.5939" y2={2} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium tracking-wider">Grooming Expert</h5>
                                    {/* <h6 className="mt-2 text-indigo2 text-sm tracking-wider">exmaple@mail.com</h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/saudkhan.jpg" className="w-full" alt="John" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                        <Link to="https://www.instagram.com/saudmdlofficial/"> 
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Saud Khan</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Top Model</h5>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 hoverDiv border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/ashish.jpg" className="w-full" alt="Dinah" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                          <Link to="https://www.instagram.com/ashish_kumar_fitness_expert/" >
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Ashish Kumar</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Fitness Expert</h5>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 hoverDiv border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/amisha.jpg" className="w-full" alt="sarah" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                          <Link to="https://www.instagram.com/amishaachaudhary/">
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Amisha Chaudhary</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Pageant Coach</h5>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 hoverDiv border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/tarunsingh.jpg" className="w-full" alt="Halena" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                          <Link to="https://www.instagram.com/tarunsinghphotography/">
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Tarun Singh</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Celebrity Photographer</h5>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 hoverDiv border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/prateekdhawan.JPG" className="w-full" alt="Halena" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                          <Link to="https://www.instagram.com/pratikdhawan/" >
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Prateek Dhawan</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Founder DCOP</h5>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 hoverDiv border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/khizar.JPG" className="w-full" alt="Halena" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                        <div className="cursor-pointer">
                                          <Link to="https://www.instagram.com/khizarhussainfashionchoreo/" >
                                          <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                  fill="white"
                />
                <path
                  d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                  fill="white"
                />
                <path
                  d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                  fill="white"
                />
              </svg>
              </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Khizar Hussain</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Fashion Choreographer</h5>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full justify-center xl:justify-end">
                            <div className="flex flex-col items-center">
                                <div className="pt-4 px-5 hoverDiv border-t-2 flex-col flex items-center border-l-2 border-r-2 border-black flex lg:w-96">
                                    <div className="relative w-full">
                                        <img src="/apala.jpg" className="w-full" alt="Halena" />
                                        <div className="flex justify-center items-center absolute opacity-0 bg-gray-900 hover:opacity-75 w-full h-full top-0">
                                            {/* <div className="cursor-pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 30 30" fill="none">
                                                    <path
                                                        d="M20.67 30V18.39H24.5583L25.14 13.8767H20.67V10.9967C20.67 9.69 21.0333 8.79833 22.9067 8.79833H25.2967V4.76167C24.1397 4.63891 22.9768 4.57937 21.8133 4.58333C18.3683 4.58333 16.01 6.68667 16.01 10.55V13.8767H12.1133V18.39H16.01V30H1.66667C1.22464 30 0.800716 29.8244 0.488155 29.5118C0.175595 29.1993 0 28.7754 0 28.3333V1.66667C0 1.22464 0.175595 0.800716 0.488155 0.488155C0.800716 0.175595 1.22464 0 1.66667 0H28.3333C28.7754 0 29.1993 0.175595 29.5118 0.488155C29.8244 0.800716 30 1.22464 30 1.66667V28.3333C30 28.7754 29.8244 29.1993 29.5118 29.5118C29.1993 29.8244 28.7754 30 28.3333 30H20.67Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div> */}
                                            <div className="cursor-pointer">
                                          <Link to="https://www.instagram.com/apalarajvanshi/">
                                            <svg
                  width={40}
                  height={40}
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.2914 5.41641C29.2236 5.42218 31.0751 6.19231 32.4414 7.55861C33.8077 8.92491 34.5778 10.7764 34.5836 12.7086V27.2914C34.5778 29.2236 33.8077 31.0751 32.4414 32.4414C31.0751 33.8077 29.2236 34.5778 27.2914 34.5836H12.7086C10.7764 34.5778 8.92491 33.8077 7.55861 32.4414C6.19231 31.0751 5.42218 29.2236 5.41641 27.2914V12.7086C5.42218 10.7764 6.19231 8.92491 7.55861 7.55861C8.92491 6.19231 10.7764 5.42218 12.7086 5.41641H27.2914ZM27.2914 2.5H12.7086C7.09375 2.5 2.5 7.09375 2.5 12.7086V27.2914C2.5 32.9062 7.09375 37.5 12.7086 37.5H27.2914C32.9062 37.5 37.5 32.9062 37.5 27.2914V12.7086C37.5 7.09375 32.9062 2.5 27.2914 2.5Z"
                    fill="white"
                  />
                  <path
                    d="M29.479 12.707C29.0464 12.707 28.6234 12.5787 28.2637 12.3384C27.904 12.098 27.6236 11.7564 27.458 11.3566C27.2925 10.9569 27.2491 10.5171 27.3335 10.0928C27.4179 9.66844 27.6263 9.27866 27.9322 8.97274C28.2381 8.66681 28.6279 8.45847 29.0522 8.37406C29.4766 8.28966 29.9164 8.33298 30.3161 8.49855C30.7158 8.66411 31.0575 8.94449 31.2978 9.30422C31.5382 9.66395 31.6665 10.0869 31.6665 10.5195C31.6671 10.807 31.611 11.0917 31.5013 11.3574C31.3915 11.6231 31.2304 11.8645 31.0272 12.0677C30.8239 12.271 30.5825 12.4321 30.3169 12.5418C30.0512 12.6515 29.7664 12.7076 29.479 12.707Z"
                    fill="white"
                  />
                  <path
                    d="M20 14.1664C21.1538 14.1664 22.2816 14.5085 23.241 15.1495C24.2003 15.7905 24.948 16.7016 25.3895 17.7676C25.8311 18.8335 25.9466 20.0065 25.7215 21.1381C25.4964 22.2697 24.9408 23.3091 24.125 24.125C23.3091 24.9408 22.2697 25.4964 21.1381 25.7215C20.0065 25.9466 18.8335 25.8311 17.7676 25.3895C16.7016 24.948 15.7905 24.2003 15.1495 23.241C14.5085 22.2816 14.1664 21.1538 14.1664 20C14.1681 18.4533 14.7832 16.9705 15.8769 15.8769C16.9705 14.7832 18.4533 14.1681 20 14.1664ZM20 11.25C18.2694 11.25 16.5777 11.7632 15.1388 12.7246C13.6998 13.6861 12.5783 15.0527 11.9161 16.6515C11.2538 18.2504 11.0805 20.0097 11.4181 21.707C11.7558 23.4044 12.5891 24.9635 13.8128 26.1872C15.0365 27.4109 16.5956 28.2443 18.293 28.5819C19.9903 28.9195 21.7496 28.7462 23.3485 28.084C24.9473 27.4217 26.3139 26.3002 27.2753 24.8612C28.2368 23.4223 28.75 21.7306 28.75 20C28.75 17.6794 27.8281 15.4538 26.1872 13.8128C24.5462 12.1719 22.3206 11.25 20 11.25Z"
                    fill="white"
                  />
                </svg>
                </Link>
                                            </div>
                                            {/* <div className="cursor-pointer">
                                                <svg className="ml-9" xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 30 30" fill="none">
                                                    <path
                                                        d="M25.5583 25.565H21.1167V18.6033C21.1167 16.9433 21.0833 14.8067 18.8 14.8067C16.485 14.8067 16.1317 16.6133 16.1317 18.4817V25.565H11.6883V11.25H15.955V13.2H16.0133C16.61 12.0767 18.06 10.8883 20.2267 10.8883C24.7267 10.8883 25.56 13.8517 25.56 17.7067V25.565H25.5583ZM6.67167 9.29167C6.33259 9.29211 5.99677 9.22562 5.68344 9.09601C5.37011 8.9664 5.08545 8.77623 4.84576 8.53639C4.60608 8.29655 4.41609 8.01176 4.28668 7.69835C4.15728 7.38494 4.09101 7.04907 4.09167 6.71C4.092 6.19972 4.24363 5.70101 4.5274 5.27691C4.81117 4.85281 5.21432 4.52239 5.68588 4.32742C6.15744 4.13245 6.67623 4.08169 7.17663 4.18157C7.67704 4.28144 8.13659 4.52746 8.49718 4.88851C8.85777 5.24956 9.10319 5.70943 9.20242 6.20997C9.30164 6.7105 9.25022 7.22922 9.05464 7.70053C8.85906 8.17184 8.52811 8.57456 8.10365 8.85778C7.67919 9.141 7.18028 9.292 6.67 9.29167H6.67167ZM8.89833 25.565H4.44333V11.25H8.9V25.565H8.89833ZM27.7833 0H2.215C0.988333 0 0 0.966667 0 2.16167V27.8383C0 29.0333 0.99 30 2.21333 30H27.7767C29 30 30 29.0333 30 27.8383V2.16167C30 0.966667 29 0 27.7767 0H27.7817H27.7833Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-2xl text-gray-800 font-semibold mt-2">Apala Rajvanshi</h4>
                                <div className="pb-4 px-5 mt-4 flex flex-col border-b-2 border-l-2 border-r-2 border-black w-full items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={31} viewBox="0 0 3 31" fill="none">
                                        <line x1="1.5" y1="-4.88672e-08" x2="1.5" y2={31} stroke=" #4338CA" strokeWidth={3} />
                                    </svg>
                                    <h5 className="text-gray-800 text-lg mt-2 font-medium">Top Model</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default function Registerhero ()  {
    return (
        <>
       <div class="relative  from-gray-100 to-gray-500 h-[70vh]  text-white overflow-hidden">
  <div class="absolute inset-0 hidden sm:block">
    <img src="/hero.jpg" alt="Background" class="object-cover -mt-48 object-center" />
    <div class="absolute inset-0 bg-black opacity-20"></div>
  </div>

  {/* <div class="block md:hidden">
    <img src="/pic16.png" alt="Background" class="object-cover  object-center" />
    <div class="absolute inset-0 bg-black opacity-20"></div>
  </div> */}
  


 
  

                       
                    

   
 
  
</div>

        </>
    );
  };